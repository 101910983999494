<template>
  <div id="page" class="p-0 d-flex flex-column h-100">
    <div>
      <bar-component />
      <header-component />
    </div>
    <div id="content" class="p-3 flex-grow-1 d-flex align-items-center">
      <div class="col col-12 col-md-8 mx-auto">
        <h2 class="text-center">{{ $t("home.welcome") }}</h2>
        <div class="mt-4">
          <p v-html="$t('home.introduction')"></p>
        </div>
        <div class="mt-4 text-center">
          <button class="btn btn-lg btn-primary" @click="gotoHousingTypeScreen()">{{ $t("global.start_label") }}</button>
        </div>
      </div> 
    </div>
  </div>

</template>

<script>
import BarComponent from '../components/BarComponent.vue'
import HeaderComponent from '../components/HeaderComponent.vue'

export default {
  name: 'HomeView',
  components: {
    'bar-component': BarComponent,
    'header-component': HeaderComponent
  },
  mounted() {
  },
  methods: {
    gotoHousingTypeScreen(){
      this.$router.push({ name: 'HousingType'});
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../sass/variables.scss';
  
 
</style>