<template>
    <header class="d-flex align-items-center ps-3">
      <h1 class="m-0">{{ $t("home.title") }}</h1>
    </header>
  
</template>

<script>
export default {
  name: 'HeaderComponent'
}
</script>

<style scoped>

  header {
    height: 40px;
    background-color: #28348B;
    background-image: url(../assets/images/bg-header.png);
    
    background-position: center right;
    background-repeat: no-repeat;
  }

  h1 {
    color: white;
    font-size: 18px;
    font-weight: 700;
    background-image: url(../assets/images/logo-vmc.svg);
    background-size: 30px;
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 45px;
    line-height: 40px;


  }



</style>
