<template>
  <div id="page" class="p-0 d-flex flex-column h-100">
    <div>
      <bar-component />
      <header-component />
    </div>
    <div id="content" class="p-3 flex-grow-1 d-flex flex-column align-items-center">
      <div class="d-flex flex-grow-1 flex-column justify-content-center col col-12 col-md-8">
        <div class="mb-5">
          <p v-html="$t('housingType.introduction')"></p>
        </div>
        <div class="mt-4 text-center">
          <h4>{{ $t("housingType.instruction") }}</h4>
        </div>
        <div class="mt-4 d-flex justify-content-center gap-5">
          <div class="choice individual text-center" :class="{active: housingTypeCompatibility == 'individual'}" @click="housingTypeChange('individual')">
            <span>{{ $t("housingType.individual_label") }}</span>
          </div>
          <div class="choice collective text-center" :class="{active: housingTypeCompatibility == 'collective'}" @click="housingTypeChange('collective')">
            <span>{{ $t("housingType.collective_label") }}</span>
          </div>
        </div>
        
      </div>
      
    </div>
    <div class="d-flex w-100 justify-content-end fixed-bottom">
      <div class="">
        <button class="btn btn-lg btn-primary" :disabled="housingTypeCompatibility==null" @click="gotoAirTypeScreen()">{{ $t("global.next_label") }}</button>
      </div>
    </div>
    
  </div>


</template>

<script>
import DataManager from '@/model/DataManager'

import BarComponent from '../components/BarComponent.vue'
import HeaderComponent from '../components/HeaderComponent.vue'

export default {
  name: 'HousingTypeView',
  components: {
    'bar-component': BarComponent,
    'header-component': HeaderComponent
  },
  data: function () {
    return {
      data: null,
      housingTypeCompatibility: null
    }
  },
  mounted() {
   this.housingTypeCompatibility = DataManager.getHousingTypeCompatibility();
  },
  methods: {
    housingTypeChange(housingType)
    {
      DataManager.eraseBoxPlaceCompatibility();
      DataManager.setHousingTypeCompatibility(housingType);
      this.housingTypeCompatibility = DataManager.getHousingTypeCompatibility();
    },
    gotoAirTypeScreen(){
      this.$router.push({ name: 'AirType'});
    }
  }
}
</script>


<style scoped lang="scss">
@import '../sass/variables.scss';

.choice {
  width: 100px;
  padding-top: 100px;
  background-position: top center;
  background-repeat: no-repeat;
  font-weight: 700;
}

.choice.active {
  color: $blue;
}

.choice.individual {
  background-image: url(../assets/images/housing-type-individual-off.svg);
}

.choice.individual.active {
  background-image: url(../assets/images/housing-type-individual-on.svg);
}

.choice.collective {
  background-image: url(../assets/images/housing-type-collective-off.svg);
}

.choice.collective.active {
  background-image: url(../assets/images/housing-type-collective-on.svg);
}

</style>