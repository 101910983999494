<template>
  <div class="bar">

  </div>
  
</template>

<script>
export default {
  name: 'MarkComponent'
}
</script>

<style scoped>
.bar {
  background-color: white;
  min-height: 40px;
  min-width: 100px;
  background-image: url(../assets/images/logo.png);
  background-size: 80px;
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: center;
}

  .bar {
    float: left;
    width: 100px;
  }

</style>
