<template>
  <div id="page" class="p-0 d-flex flex-column h-100">
    <div>
      <bar-component />
      <header-component />
    </div>
    <div id="content" class="p-3 flex-grow-1 d-flex flex-column align-items-center">
      <div class="d-flex flex-grow-1 w-100">
        <div class="row d-flex flex-grow-1">
          <div class="col col-10 col-md-11 d-flex flex-column justify-content-center">
            <div class="text-center">
              <h4 class="mb-3" v-html="$t('extractionPlace.instruction')"></h4>
            </div>
            <div class="row gy-3">
              
              <div class="col col-12 d-flex flex-column align-items-center justify-content-center">
                
                  <scene-component ref="scene" :layer="'extraction'" :draggableActive="true" />
                  
              </div>  
            </div>
          </div>
          <environment-component />
        </div>
      </div>
    </div>

    <div class="d-flex w-100 justify-content-between fixed-bottom">
      <div class="">
        <button class="btn btn-lg btn-primary" @click="gotoPreviousScreen()">{{ $t("global.previous_label") }}</button>
      </div>
      
      <div class="">
        <button class="btn btn-lg btn-primary" :disabled="!readyToNext" @click="gotoNextScreen()">{{ $t("global.next_label") }}</button>
      </div>
    </div>
    
  </div>


</template>

<script>


import BarComponent from '../components/BarComponent.vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import EnvironmentComponent from '../components/EnvironmentComponent.vue';
import SceneComponent from '../components/SceneComponent.vue';


export default {
  name: 'ExtractionPlaceView',
  components: {
    'bar-component': BarComponent,
    'header-component': HeaderComponent,
    'environment-component': EnvironmentComponent,
    'scene-component': SceneComponent,
  },
  data: function () {
    return {
      
    }
  },
  computed: {
    readyToNext()
    {
      
      return true;
    }
  },
  mounted() {
   
  },
  beforeRouteEnter() {
    if(this && this.$refs)
    {
      if(this.$refs.scene)
      {
        this.$refs.scene.addListeners();
      }
    }
  }, 
  beforeRouteLeave() {
    if(this && this.$refs)
    {
      if(this.$refs.scene)
      {
        this.$refs.scene.removeListeners();
      }
    }
  }, 
  methods:
  {
    gotoPreviousScreen(){
  
     
      this.$router.push({ name: 'InletPlace'});
      
      //this.$router.go(-1);
      },
    gotoNextScreen(){
        
        this.$router.push({ name: 'Simulation'});
    }
        
  }
}
</script>


<style scoped lang="scss">
@import '../sass/variables.scss';


</style>