<template>
  <div id="page" class="p-0 d-flex flex-column h-100">
    <div>
      <bar-component />
      <header-component />
    </div>
    <div id="content" class="p-3 flex-grow-1 d-flex flex-column align-items-center">
      <div class="d-flex flex-grow-1 w-100">
        <div class="row d-flex flex-grow-1">
          <div class="col col-10 col-md-11 d-flex flex-column justify-content-center">
            <div class="row gy-3">
              
              <div class="col col-12 d-flex flex-column align-items-center justify-content-center">
                  <scene-component ref="scene" :refresh="refreshScene" :layer="'all'" :draggableActive="false" @evaluated="evaluated" />
              </div>  

              

            </div>
          </div>
          <environment-component />
        </div>
      </div>
      


      <div class="d-flex w-100 justify-content-between p-2">
        <div class="">
          <button class="btn btn-lg btn-primary" @click="gotoPreviousScreen()">{{ $t("global.previous_label") }}</button>
        </div>
        <div class="result">
          <div v-if="detailedErrors && errors" class="error-selection m-0 p-0">
                <a class="btn btn-lg btn-primary" @click="detailedErrorsOpened=!detailedErrorsOpened" :class="{'on': detailedErrorsOpened}" ></a>
          </div>
          <div v-if="!errors" class="success">Bravo, le système est conforme sous tous ses aspects.</div>
          <div v-if="errors" class="failed">Votre configuration présente <span class="number">{{errorsNumber}}</span> incompatibilité<span v-if="errorsNumber>1">s</span>.</div>
          
        </div>
        
      </div>

      <div v-if="errorMessages && detailedErrors && detailedErrorsOpened" class="row w-100 p-0 m-0">
        <div class="errors-detailed" :style="{'margin-top': 'calc(-110px - 40px * '+errorMessages.length+')'}">
          <div v-for="(errorMessage, index) of errorMessages" v-bind:key="index" class="error"><span class="number">{{errorMessage.num}}</span> {{errorMessage.msg}}</div>
        </div>
      </div>
      

      <div class="row w-100 p-0 m-0">
        <div v-for="category of categories" v-bind:key="category.id" class="col col-3">
          <div class="product col col-12 p-1 m-0" :class="category.code">
            <div class="product-header">
              <div class="product-title"></div>
              <div class="product-change" @click="openModal(category)" data-bs-toggle="modal" data-bs-target="#productsModal">
              </div>
            </div>
            <div v-if="productByCategory[category.code]" class="product-content row mt-0">
              <div class="product-image col col-12 d-flex align-items-center justify-content-center text-center">
                <img :src="productByCategory[category.code].image" />
              </div>
              <div class="product-infos col col-12 text-center mt-1">
                <p class=""><strong>{{productByCategory[category.code].name}}</strong></p>
              </div>
            </div>
            <div v-if="!productByCategory[category.code]" class="product-content d-flex justify-content-center align-items-center mt-3">
              <div class="product-selection">
                <a class="btn btn-lg btn-primary" @click="openModal(category)" data-bs-toggle="modal" data-bs-target="#productsModal">{{ $t("components.choose_label") }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>


  <!-- Modal -->
  <products-modal-component :category="category" @changed="refresh()" />


</template>

<script>

import BarComponent from '../components/BarComponent.vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import EnvironmentComponent from '../components/EnvironmentComponent.vue';
import SceneComponent from '../components/SceneComponent.vue';
import moduleService from '@/services/moduleService';
import DataManager from '@/model/DataManager';
import ProductsModalComponentVue from '@/components/ProductsModalComponent.vue';


export default {
  name: 'SimulationView',
  components: {
    'bar-component': BarComponent,
    'header-component': HeaderComponent,
    'environment-component': EnvironmentComponent,
    'scene-component': SceneComponent,
    'products-modal-component': ProductsModalComponentVue
  },
  data: function () {
    return {
      categories: null,
      category: null,
      refreshScene: false,
      productByCategory: {},
      errors: null,
      errorMessages: null,
      detailedErrors: false,
      detailedErrorsOpened: false
    }
  },
  computed: {
    readyToNext()
    {
      return true;
    },
    errorsNumber()
    {
      var num = 0;
      if(this.errors)
      {
        for (const [key, value] of Object.entries(this.errors)) 
        {
          if(value)
          {
              num += value.length;
          }  
          
        }
      }

      return num;
    }
  },
  mounted() {
    window.addEventListener("message", (event) => {
      setTimeout(()=>{
        this.init();
      },100);
          
    }, false);

    this.init();

  },
  beforeRouteEnter() {
    if(this && this.$refs)
    {
      if(this.$refs.scene)
      {
        this.$refs.scene.addListeners();
      }
    }
  }, 
  beforeRouteLeave() {
    if(this && this.$refs)
    {
      if(this.$refs.scene)
      {
        this.$refs.scene.removeListeners();
      }
    }
  }, 
  methods:
  {
    init()
    { 
      if(DataManager.getLiveData())
      {
        this.detailedErrors = (DataManager.getLiveData().correction) ? DataManager.getLiveData().correction : false;
      }
      this.getCategories();
    },
    refresh()
    {
      this.refreshScene = true;
      this.init();
    },
    openModal(category)
    {
      this.category = category;
    },
    getProductForCategories() {
      var data = DataManager.getData();
      
      for(let category of this.categories)
      {
        let productId = data[category.code];
        if(productId)
        {
          moduleService.getProductById(productId).then(
            response => {
              
              this.productByCategory[category.code] = response;
              this.refreshScene = false;
            },
            errors => {
              
            }
          );
        }
      }
    },
    getCategories(){
      moduleService.getCategories().then(
        response => {
          
          this.categories = response;
          this.getProductForCategories();
        },
        errors => {
          
        }
      );
    },
    gotoPreviousScreen(){
  
      
      this.$router.push({ name: 'ExtractionPlace'});
      
      //this.$router.go(-1);
    } ,
    evaluated(results)
    {
      //console.clear();
      console.log(results);
      if(results.success == true)
      {
        this.errors = null;
        this.errorMessages = null;
      }else
      {
        this.errors = results.errors;
        this.prepareErrorMessages();

      }
      
    },
    prepareErrorMessages()
    {

      this.errorMessages = [];

      for(const [key, errors] of Object.entries(this.errors))
      {
        
        if(key == 'housingType')
        {
          if(errors.length > 0)
          {
            this.errorMessages.push(
              {
                num: errors.length,
                msg: (errors.length>1) ? this.$t('simulation.errors.theHousingTypeMultiple')+' '+this.$t('simulation.'+DataManager.getHousingTypeCompatibility()) : this.$t('simulation.errors.theHousingTypeSingle')+' '+this.$t('simulation.'+DataManager.getHousingTypeCompatibility()) 
              }
            );
          }
        }

        if(key == 'airType')
        {
          if(errors.length > 0)
          {
            this.errorMessages.push(
              {
                num: errors.length,
                msg: (errors.length>1) ? this.$t('simulation.errors.theAirTypeMultiple')+' '+this.$t('simulation.'+DataManager.getAirTypeCompatibility()) : this.$t('simulation.errors.theAirTypeSingle')+' '+this.$t('simulation.'+DataManager.getAirTypeCompatibility()) 
              }
            );
          }
        }

        if(key == 'airSystem')
        {
          if(errors.length > 0)
          {
            this.errorMessages.push(
              {
                num: errors.length,
                msg: (errors.length>1) ? this.$t('simulation.errors.theAirSystemMultiple')+' '+this.$t('simulation.'+DataManager.getAirSystemCompatibility()) : this.$t('simulation.errors.theAirSystemSingle')+' '+this.$t('simulation.'+DataManager.getAirSystemCompatibility()) 
              }
            );
          }
        }

        if(key == 'boxPlace')
        {
          if(errors.length > 0)
          {
            this.errorMessages.push(
              {
                num: errors.length,
                msg: (errors.length>1) ? this.$t('simulation.errors.theBoxPlaceMultiple')+' '+this.$t('simulation.'+DataManager.getBoxPlaceCompatibility()) : this.$t('simulation.errors.theBoxPlaceSingle')+' '+this.$t('simulation.'+DataManager.getBoxPlaceCompatibility()) 
              }
            );
          }
        }

        if(key == 'inlet')
        {
          let requiredErrorsCount = 0;
          let notrequiredErrorsCount = 0;
          for(let err of errors)
          {
            if(err.value == "required")
            {
              requiredErrorsCount++;
            }else if(err.value == "not required")
            {
              notrequiredErrorsCount++;
            }
          }

          if(requiredErrorsCount > 0)
          {
            this.errorMessages.push(
              {
                num: requiredErrorsCount,
                msg: (requiredErrorsCount>1) ? this.$t('simulation.errors.theInletsRequiredMultiple') : this.$t('simulation.errors.theInletsRequiredSingle') 
              }
            );
          }

          if(notrequiredErrorsCount > 0)
          {
            this.errorMessages.push(
              {
                num: notrequiredErrorsCount,
                msg: (notrequiredErrorsCount>1) ? this.$t('simulation.errors.theInletsNotRequiredMultiple') : this.$t('simulation.errors.theInletsNotRequiredSingle') 
              }
            );
          }

        }    
        
        
        if(key == 'extraction')
        {
          let requiredErrorsCount = 0;
          let notrequiredErrorsCount = 0;
          for(let err of errors)
          {
            if(err.value == "required")
            {
              requiredErrorsCount++;
            }else if(err.value == "not required")
            {
              notrequiredErrorsCount++;
            }
          }

          if(requiredErrorsCount > 0)
          {
            this.errorMessages.push(
              {
                num: requiredErrorsCount,
                msg: (requiredErrorsCount>1) ? this.$t('simulation.errors.theExtractionsRequiredMultiple') : this.$t('simulation.errors.theExtractionsRequiredSingle') 
              }
            );
          }

          if(notrequiredErrorsCount > 0)
          {
            this.errorMessages.push(
              {
                num: notrequiredErrorsCount,
                msg: (notrequiredErrorsCount>1) ? this.$t('simulation.errors.theExtractionsNotRequiredMultiple') : this.$t('simulation.errors.theExtractionsNotRequiredSingle') 
              }
            );
          }

        }
        
        if(key == 'productIncompatibility')
        {
          if(errors.length > 0)
          {
            this.errorMessages.push(
              {
                num: errors.length,
                msg: (errors.length>1) ? this.$t('simulation.errors.theProductsIncompatibilityMultiple') : this.$t('simulation.errors.theProductsIncompatibilitySingle') 
              }
            );
          }
        }
        
      }
    }
        
  }
}
</script>



<style scoped lang="scss">
  @import '../sass/variables.scss';
  
  .product {
    background-color: white;
  }
  
  .product .product-header {
    height: 32px;
  }
  
  .product .product-title {
    height: 32px;
    padding-left: 50px;
    padding-right: 50px;
    display: table-cell;
  
    vertical-align: middle;
    line-height: 20px;
    background-repeat: no-repeat;
    background-size: 26px 26px;
    background-position-y: center;
    background-position-x: 2px;
    color: $blue;
  }
  
  .product.box .product-title {
    background-image: url(../assets/images/ico-box.svg);
  }
  
  .product.inlet .product-title {
    background-image: url(../assets/images/ico-inlet.svg);
  }
  
  .product.extraction .product-title {
    background-image: url(../assets/images/ico-extraction.svg);
  }
  
  .product.duct .product-title {
    background-image: url(../assets/images/ico-duct.svg);
  }
  
  
  .product .product-change {
    width: 32px;
    height: 32px;
    background: url(../assets/images/ico-change-product.svg) no-repeat;
    background-size: 100%;
    float: right;
    margin-top: -32px;
  }
  
  
  .product .product-content {
  }
  
  
  .product-image img {

    height: auto;
    aspect-ratio: auto;

    max-height: 80px;
    max-width: 80px;
  }
  
  .product-selection a.btn {
    padding-left: 50px;
    background-image: url(../assets/images/ico-cart.svg);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 10px;
  }
  
  a.btn.add-cart {
    background-image: url(../assets/images/ico-cart.svg);
    background-repeat: no-repeat;
    background-position: center center;
    width: 64px;
    height: 64px;
  }


  .result {
    background-color: white;
    padding-left: 15px;

    height: 50px;
    
  }

  .result .success {
    color: $green;
    font-size: 20px;
    line-height: 50px;
    padding-right: 15px;
  } 
  
  .result .failed {
    color: $caution;
    font-size: 20px;
    line-height: 50px;
    padding-right: 65px;
    padding-left: 45px;
    background-image: url(../assets/images/ico-alert.svg);
    background-repeat: no-repeat;
    background-position: center left;
  }
  
  .result .failed .number {
    font-size: 24px;
    line-height: 50px;
    font-weight: 700;
  }

  .result .error-selection .btn {
    width: 50px;
    height: 50px;
    background-image: url(../assets/images/ico-more.svg);
    background-repeat: no-repeat;
    background-position: center center;
    float: right;
  }

  .result .error-selection .btn.on {
    background-image: url(../assets/images/ico-more-on.svg);
    background-size: 30px;
  }

  .errors-detailed {
    padding: 15px;
    background-color: white;
    border-radius: 20px 20px 0px 20px;
    position: absolute;
    width:auto;
    right: 60px;
    margin-top: -140px;
  }

  .errors-detailed .error {
    font-size: 20px;
    color: $danger;
    line-height: 40px;
  }

  .errors-detailed .error .number {
    font-size: 24px;
    font-weight: 700;
  }  

  </style>