<template>
  <div class="modal right" id="productsModal" tabindex="-1" aria-labelledby="productsModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div v-if="category" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="productsModalLabel">{{ category.name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-for="product of products" v-bind:key="product.id" class="row pt-3 pb-3 border-bottom">
            <div class="col col-3 product-image d-flex align-items-center text-center">
              <img :src="product.image" />
            </div>
            <div class="col col-7 product-infos">
              <h4>{{product.name}}</h4>
              <p>{{product.description}}</p>
            </div>
            <div class="col col-2 d-flex justify-content-end">
              <a class="btn btn-lg btn-primary add-cart" data-bs-dismiss="modal" @click="addProduct(category, product)"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataManager from '@/model/DataManager';
import moduleService from '@/services/moduleService';
export default {
  name: 'ProductsModalComponent',
  props: {
    category: Object
  },
  data: function(){
    return {
      products: []
    }
  },
  watch: {
    category: function(){
      if(this.category)
      {
        this.selectProduct();
      }
    }
    
  },
  methods: {

    selectProduct()
    {
      
      moduleService.getProductsByCategoryCode(this.category.code).then(
        response => {
          
          this.products = response;
        },
        errors => {
          
        }
      );
    },
    addProduct(category, product)
    {
      var data = DataManager.getData();
      data[category.code] = product.id;

      DataManager.setData(data);

      this.products = null;
      this.$emit("changed");
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../sass/variables.scss';
  
  
  .modal.right .modal-dialog {
    right: 0px;
    position: fixed;
    margin: auto;
    width: 680px;
    max-width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
  
  .modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
  }
  
  .modal.right .modal-body {
    padding: 15px 15px 80px;
  }
  
  .modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }
  
  .modal.right.fade.in .modal-dialog {
    right: 0;
  }
  
  
  .product {
    background-color: white;
  }
  
  .product .product-header {
    height: 40px;
  }
  
  .product .product-title {
    height: 40px;
    padding-left: 50px;
    padding-right: 50px;
    display: table-cell;
  
    vertical-align: middle;
    line-height: 20px;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-position-y: center;
    background-position-x: 4px;
    color: $blue;
  }
  
  .product.box .product-title {
    background-image: url(../assets/images/ico-box.svg);
  }
  
  .product.inlet .product-title {
    background-image: url(../assets/images/ico-inlet.svg);
  }
  
  .product.extraction .product-title {
    background-image: url(../assets/images/ico-extraction.svg);
  }
  
  .product.duct .product-title {
    background-image: url(../assets/images/ico-duct.svg);
  }
  

  
  
  .product .product-content {
    min-height: 150px;
  }
  
  
  .product-image img {
    width: 100%;
    max-width: 122px;
    max-height: 140px;
  }
  
  .product-selection a.btn {
    padding-left: 50px;
    background-image: url(../assets/images/ico-cart.svg);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 10px;
  }
  
  a.btn.add-cart {
    background-image: url(../assets/images/ico-cart.svg);
    background-repeat: no-repeat;
    background-position: center center;
    width: 64px;
    height: 64px;
  }
  </style>
