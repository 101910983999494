import DataManager from '@/model/DataManager';
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import HousingTypeView from '../views/HousingTypeView.vue';
import AirTypeView from '../views/AirTypeView.vue';
import AirSystemView from '../views/AirSystemView.vue';
import BoxPlaceView from '../views/BoxPlaceView.vue';
import InletPlaceView from '../views/InletPlaceView.vue';
import ExtractionPlaceView from '../views/ExtractionPlaceView.vue';
import ComponentsView from '../views/ComponentsView.vue';
import SimulationView from '../views/SimulationView.vue';

const defaultMeta = {
};

const routes = [
 
  {
    path: '/housingType',
    name: 'HousingType',
    component: HousingTypeView,
    meta: defaultMeta
  },
  {
    path: '/airType',
    name: 'AirType',
    component: AirTypeView,
    meta: defaultMeta
  },
  {
    path: '/airSystem',
    name: 'AirSystem',
    component: AirSystemView,
    meta: defaultMeta
  },
  {
    path: '/components',
    name: 'Components',
    component: ComponentsView,
    meta: defaultMeta
  },
  {
    path: '/boxPlace',
    name: 'BoxPlace',
    component: BoxPlaceView,
    meta: defaultMeta
  },
  {
    path: '/inletPlace',
    name: 'InletPlace',
    component: InletPlaceView,
    meta: defaultMeta
  },
  {
    path: '/extractionPlace',
    name: 'ExtractionPlace',
    component: ExtractionPlaceView,
    meta: defaultMeta
  },
  {
    path: '/simulation',
    name: 'Simulation',
    component: SimulationView,
    meta: defaultMeta
  },
  {
    path: '/home/:data',
    name: 'Home',
    component: HomeView,
    meta: defaultMeta
  }
  
];


var router = createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  var data = null;

  if(to.params.data)
  {
    DataManager.init(to.params.data);
    
    
    var screen = DataManager.getScreen();
    if(screen)
    {
      next({ name: screen });
    }else
    {
      next();
    }
  }else
  {
    next();
  }

});


export default router
