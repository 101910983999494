<template>
  <div id="page" class="p-0 d-flex flex-column h-100">
    <div>
      <bar-component />
      <header-component />
    </div>
    <div id="content" class="p-3 flex-grow-1 d-flex flex-column align-items-center">
      <div class="d-flex flex-grow-1 w-100">
        <div class="row d-flex flex-grow-1">
          <div class="col col-10 col-md-11 d-flex flex-column justify-content-center">
            <div class="text-center">
              <h4 class="mb-3">{{ $t("components.instruction") }}</h4>
            </div>
            <div class="row gy-3">
              
              <div v-for="category of categories" v-bind:key="category.id" class="col col-12 col-md-6">
                <div class="product col col-12 p-3 ms-1 me-1" :class="category.code">
                  <div class="product-header">
                    <div class="product-title">{{category.name}}</div>
                    <div class="product-change" @click="openModal(category)" data-bs-toggle="modal" data-bs-target="#productsModal">
                    </div>
                  </div>
                  <div v-if="productByCategory[category.code]" class="product-content row p-0 mt-3">
                    <div class="product-image col col-3 d-flex align-items-center text-center">
                      <img :src="productByCategory[category.code].image" />
                    </div>
                    <div class="product-infos col col-9">
                      <h4>{{productByCategory[category.code].name}}</h4>
                      <p class="mb-0">{{productByCategory[category.code].description}}</p>
                    </div>
                  </div>
                  <div v-if="!productByCategory[category.code]" class="product-content d-flex justify-content-center align-items-center mt-3">
                    <div class="product-selection">
                      <a class="btn btn-lg btn-primary" @click="openModal(category)" data-bs-toggle="modal" data-bs-target="#productsModal">{{ $t("components.choose_label") }}</a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>                       

          </div>
          <environment-component />
        </div>
      </div>
      
      
    </div>

    <div class="d-flex w-100 justify-content-between fixed-bottom">
      <div class="">
        <button class="btn btn-lg btn-primary" @click="gotoPreviousScreen()">{{ $t("global.previous_label") }}</button>
      </div>
      
      <div class="">
        <button class="btn btn-lg btn-primary" :disabled="!readyToNext" @click="gotoNextScreen()">{{ $t("global.next_label") }}</button>
      </div>
    </div>
    
  </div>


  <!-- Modal -->
  <products-modal-component :category="category" @changed="init()" />
  

</template>

<script>
import DataManager from '@/model/DataManager';

import BarComponent from '../components/BarComponent.vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import EnvironmentComponent from '../components/EnvironmentComponent.vue';

import moduleService from '@/services/moduleService';
import ProductsModalComponentVue from '@/components/ProductsModalComponent.vue';

export default {
  name: 'HousingTypeView',
  components: {
    'bar-component': BarComponent,
    'header-component': HeaderComponent,
    'environment-component': EnvironmentComponent,
    'products-modal-component': ProductsModalComponentVue
  },
  data: function () {
    return {
      categories: null,
      category: null,
      productByCategory: {}
    }
  },
  computed: {
    readyToNext()
    {
      if(this.productByCategory)
      {
        if(this.productByCategory.box && this.productByCategory.inlet && this.productByCategory.extraction && this.productByCategory.duct)
        {
          return true;
        }
        
      }

      return false;
    }
  },
  mounted() {
    this.init();
  },
  methods:
  {
    init()
    {
      
      this.getCategories();
    },
    openModal(category)
    {
      this.category = category;
    },
    getProductForCategories() {
      var data = DataManager.getData();
      
      for(let category of this.categories)
      {
        let productId = data[category.code];
        if(productId)
        {
          moduleService.getProductById(productId).then(
            response => {
              
              this.productByCategory[category.code] = response;
            },
            errors => {
              
            }
          );
        }
      }
    },
    getCategories(){
      moduleService.getCategories().then(
        response => {
          
          this.categories = response;
          this.getProductForCategories();
        },
        errors => {
          
        }
      );
    },
    gotoPreviousScreen(){
      this.$router.push({ name: 'AirSystem'});
      //this.$router.go(-1);
    },
    gotoNextScreen(){

      if(DataManager.getHousingTypeCompatibility() == 'individual')
      {
        this.$router.push({ name: 'BoxPlace'});
      }else
      {
        this.$router.push({ name: 'InletPlace'});
      }
      
    },
    addProduct(category, product)
    {
      var data = DataManager.getData();
      data[category.code] = product.id;

      DataManager.setData(data);
      this.init();
    }
  }
}
</script>


<style scoped lang="scss">
@import '../sass/variables.scss';

.product {
  background-color: white;
}

.product .product-header {
  height: 40px;
}

.product .product-title {
  height: 40px;
  padding-left: 50px;
  padding-right: 50px;
  display: table-cell;

  vertical-align: middle;
  line-height: 20px;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position-y: center;
  background-position-x: 4px;
  color: $blue;
}

.product.box .product-title {
  background-image: url(../assets/images/ico-box.svg);
}

.product.inlet .product-title {
  background-image: url(../assets/images/ico-inlet.svg);
}

.product.extraction .product-title {
  background-image: url(../assets/images/ico-extraction.svg);
}

.product.duct .product-title {
  background-image: url(../assets/images/ico-duct.svg);
}


.product .product-change {
  width: 40px;
  height: 40px;
  background: url(../assets/images/ico-change-product.svg) no-repeat;
  float: right;
  margin-top: -40px;
}


.product .product-content {
  min-height: 140px;
}


.product-image img {
  width: 100%;
  max-width: 122px;
  max-height: 140px;
}

.product-selection a.btn {
  padding-left: 50px;
  background-image: url(../assets/images/ico-cart.svg);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 10px;
}

a.btn.add-cart {
  background-image: url(../assets/images/ico-cart.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 64px;
  height: 64px;
}
</style>