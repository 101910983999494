const base64json = require('base64json');

class DataManager {
    constructor() {
        if (!DataManager.instance) {
            return DataManager.instance;
        }

        DataManager.instance = this;

        return this;
    }

	static init(data)
	{
        if(!this.initialized)
        {
            this.setData( JSON.parse(JSON.stringify(base64json.parse(data))) );

            window.addEventListener("message", (event) => {
                
                if (event.origin !== process.env.VUE_APP_PUBLIC_URL)
                {
                    return;
                }
                
                this.setLiveData(event.data);
                

            }, false);
            
            window.parent.postMessage("getLiveData", "*");

            this.initialized = true;
        }
    }

    static setData(data)
	{
       
        if(data)
        {
            this._data = data;
        }else
        {
            this._data = {};
        }
        
    }

    static setLiveData(liveData)
	{
       
        if(liveData)
        {
            this._liveData = liveData;
        }else
        {
            this._liveData = {};
        }
        
    }

    static getData()
    {
        return this._data;
    }

    static getLiveData()
    {
        return this._liveData;
    }

    static getSessionId()
    {
        return this._sessionId;
    }

    static getScreen()
    {
        return this._data.screen;
    }

    static getHousingTypeCompatibility()
    {
        return this._data.housingTypeCompatibility;
    }

    static setHousingTypeCompatibility(housingType)
    {
        this._data.housingTypeCompatibility = housingType;
    }

    static getAirTypeCompatibility()
    {
        return this._data.airTypeCompatibility;
    }

    static setAirTypeCompatibility(airType)
    {
        this._data.airTypeCompatibility = airType;
    }

    static getAirSystemCompatibility()
    {
        return this._data.airSystemCompatibility;
    }

    static setAirSystemCompatibility(airSystem)
    {
        this._data.airSystemCompatibility = airSystem;
    }  
    
    static getBoxPlaceCompatibility()
    {
        return this._data.boxPlaceCompatibility;
    }

    
    static eraseBoxPlaceCompatibility()
    {
        delete this._data.boxPlaceCompatibility;
    }

    static setBoxPlaceCompatibility(boxPlace)
    {
        this._data.boxPlaceCompatibility = boxPlace;
    } 
    
    static getSelectedProductFor(categoryCode)
    {
        return this._data[categoryCode];
    }


    static getInletPlaces()
    {
        return this._data.inletPlaces;
    }

    static setInletPlaces(inletPlaces)
    {
        this._data.inletPlaces = inletPlaces;
    } 

    static getExtractionPlaces()
    {
        return this._data.extractionPlaces;
    }

    static setExtractionPlaces(extractionPlaces)
    {
        this._data.extractionPlaces = extractionPlaces;
    }     

}
module.exports = DataManager;