<template>
  <div class="col col-2 col-md-1 m-0 p-0 d-flex flex-column align-items-center">
      <div v-if="housingTypeCompatibility" class="environment-choice housing-type text-center" :class="housingTypeCompatibility">
        {{ $t("environment.housingType."+housingTypeCompatibility) }}
      </div>
      <div v-if="airTypeCompatibility" class="mt-2 environment-choice air-type text-center" :class="airTypeCompatibility">
        {{ $t("environment.airType."+airTypeCompatibility) }}
      </div>
      <div v-if="airSystemCompatibility" class="mt-2 environment-choice air-system text-center" :class="airSystemCompatibility">
        {{ $t("environment.airSystem."+airSystemCompatibility) }}
      </div>
  </div>
</template>

<script>
import DataManager from '@/model/DataManager';
export default {
  name: 'HeaderComponent',
  data: function () {
    return {
      housingTypeCompatibility: null,
      airTypeCompatibility: null,
      airSystemCompatibility: null
    }
  },
  mounted (){
    
      this.housingTypeCompatibility = DataManager.getHousingTypeCompatibility();
      this.airTypeCompatibility = DataManager.getAirTypeCompatibility();
      this.airSystemCompatibility = DataManager.getAirSystemCompatibility();
  }
}
</script>

<style scoped lang="scss">
@import '../sass/variables.scss';

  .environment-choice {
    color: $blue;
    
    min-height: 64px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 44px 44px;
    padding-top: 56px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }

  .environment-choice.housing-type.individual {
    background-image: url(../assets/images/housing-type-individual-border.svg);
  }

  .environment-choice.housing-type.collective {
    background-image: url(../assets/images/housing-type-collective-border.svg);
  }

  .environment-choice.air-type.simple {
    background-image: url(../assets/images/air-type-simple-border.svg);
  }

  .environment-choice.air-type.double {
    background-image: url(../assets/images/air-type-double-border.svg);
  }

  .environment-choice.air-system.auto {
    background-image: url(../assets/images/air-system-auto-border.svg);
  }

  .environment-choice.air-system.humidity {
    background-image: url(../assets/images/air-system-humidity-border.svg);
  }




</style>
