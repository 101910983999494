<template>
  <div id="page" class="p-0 d-flex flex-column h-100">
    <div>
      <bar-component />
      <header-component />
    </div>
    <div id="content" class="p-3 flex-grow-1 d-flex flex-column align-items-center">
      <div class="d-flex flex-grow-1 w-100">
        <div class="row d-flex flex-grow-1">
          <div class="col col-10 col-md-11 d-flex flex-column justify-content-center">
            <div class="text-center">
              <h4 class="mb-3" v-html="$t('boxPlace.instruction')"></h4>
            </div>
            <div class="row gy-3">
              
              <div class="col col-12 d-flex flex-column align-items-center justify-content-center">
                  <div class="w-100">
                    <div id="scene" class="">
                      <div class="scene-container mx-auto p-0" :style="'width: '+(sceneWidth*sceneScale)+'px; height: '+(sceneHeight*sceneScale)+'px; transform-origin: top left; transform: scale('+sceneScale+');'">
                        <div class="scene-background">
                          <img src="/images/scenes/face/bg-face.png">
                        </div>
                        <div class="scene-drop-images">
                          
                          <img v-if="areaChosen == 'floor' && itemsFloor.length>0" :src="'/images/scenes/face/box-'+itemsFloor[0].codeImage3DFace+'-floor.png'" style="left: 1100px; top: 700px;">
                          <img v-if="areaChosen == 'ceiling' && itemsCeiling.length>0" :src="'/images/scenes/face/box-'+itemsCeiling[0].codeImage3DFace+'-ceiling.png'" style="left: 1100px; top: 547px;">
                          <img v-if="areaChosen == 'attics' && itemsAttics.length>0" :src="'/images/scenes/face/box-'+itemsAttics[0].codeImage3DFace+'-attics.png'" style="left: 1100px; top: 320px;">
                          
                        </div>
                        <div class="scene-drop-areas">
                          
                          <draggable id="floor" :list="itemsFloor" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'floor'}" style="width: 879px; height: 240px; left: 527px; top: 700px;">
                            <template #item="{element}">
                              <div :data-id="element.id" class="drag-object hidden">
                              </div>
                            </template>
                          </draggable>
                        
                          <draggable id="ceiling" :list="itemsCeiling" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'ceiling'}" style="width: 879px; height: 138px; left: 527px; top: 547px;">
                            <template #item="{element}">
                              <div :data-id="element.id" class="drag-object hidden">
                              </div>
                            </template>
                          </draggable>

                          <draggable id="attics" :list="itemsAttics" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'attics'}" style="width: 879px; height: 186px; left: 527px; top: 320px;">
                            <template #item="{element}">
                              <div :data-id="element.id" class="drag-object hidden">
                              </div>
                            </template>
                          </draggable>
                        
                        </div>
                        <div class="scene-remove-images">
                            <a v-if="areaChosen == 'floor'" class="btn btn-lg btn-primary remove" @click="reset()" style="left: 527px; top: 700px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
                            <a v-if="areaChosen == 'ceiling'" class="btn btn-lg btn-primary remove" @click="reset()" style="left: 527px; top: 547px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
                            <a v-if="areaChosen == 'attics'" class="btn btn-lg btn-primary remove" @click="reset()" style="left: 527px; top: 320px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="base-area d-flex flex-column align-items-center justify-content-center">
                    <draggable id="dock" :list="items" item-key="id" group="items" :move="move" @remove="remove" class="d-flex align-items-center justify-content-center" :class="{'over': areaOver == 'dock'}">
                      <template #item="{element}">
                        <div :data-id="element.id" class="drag-object d-flex align-items-center justify-content-center">
                          <img :src="element.image" />
                        </div>
                      </template>
                    </draggable>
                    <h4 v-if="box" class="text-center">{{box.name}}</h4>
                  </div>

                
                  
                
                  
              </div>  
            </div>
          </div>
          <environment-component />
        </div>
      </div>
      
      
    </div>
    <div class="d-flex w-100 justify-content-between fixed-bottom">
        <div class="">
          <button class="btn btn-lg btn-primary" @click="gotoPreviousScreen()">{{ $t("global.previous_label") }}</button>
        </div>
        
        <div class="">
          <button class="btn btn-lg btn-primary" :disabled="!readyToNext" @click="gotoNextScreen()">{{ $t("global.next_label") }}</button>
        </div>
      </div>
    
  </div>


</template>

<script>
import $ from "jquery";
import draggable from 'vuedraggable';

import DataManager from '@/model/DataManager';

import BarComponent from '../components/BarComponent.vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import EnvironmentComponent from '../components/EnvironmentComponent.vue';
import moduleService from "@/services/moduleService";

export default {
  name: 'BoxPlaceView',
  components: {
    draggable,
    'bar-component': BarComponent,
    'header-component': HeaderComponent,
    'environment-component': EnvironmentComponent
  },
  data: function () {
    return {
      sceneWidth: 1920,
      sceneHeight: 1080,
      sceneMaxWidth: 1920,
      sceneScale: 1,      
      areaOver: null,
      areaChosen: null,
      boxPlaceCompatibility: null,
      box: null,
      items: [],
      itemsFloor: [],
      itemsCeiling: [],
      itemsAttics: []
    }
  },
  computed: {
    readyToNext()
    {
      if(this.areaChosen == "floor" || this.areaChosen == "ceiling" || this.areaChosen == "attics")
      {
        return true;
      }

      return false;
    }
  },
  mounted() {
   
   this.init();

   if(!window.onresize)
    {
      this.addListeners();
    }
  },
  beforeRouteEnter() {
    if(this)
    {
      this.addListeners();
    }
    
  }, 
  beforeRouteLeave() {
    if(this)
    {
      this.removeListeners();    
    }
  }, 
  methods:
  {
    removeListeners()
    {
      window.onresize = null;
    },
    addListeners()
    {
      window.onresize = ()=> { this.resize(); };
    },
    resize()
      {
        
        let domSceneWidth = $("#app").width()/12*10;
        let screenSceneHeight = screen.availHeight;
        let scale = 1;
        let marginTopAndBottom = 400;
        
        if(domSceneWidth > this.sceneMaxWidth)
        {
          domSceneWidth = this.sceneMaxWidth;
        }
        
        scale = domSceneWidth / this.sceneWidth;

        if( (scale * this.sceneHeight) > (screenSceneHeight - marginTopAndBottom))
        {
          let scaleSupp = (screenSceneHeight - marginTopAndBottom) / (scale * this.sceneHeight);
          scale = scale * scaleSupp;
        }

        this.sceneScale = scale;
        
      },
    move(evt) {
      //console.log(evt);
      this.areaOver = evt.to.id;
    },
    remove(evt) {
      console.log("remove from: "+evt.from.id+" to: "+evt.to.id);
      this.areaChosen = evt.to.id;

      DataManager.setBoxPlaceCompatibility(this.areaChosen);

      this.areaOver = null;
    },
    reset() {
      DataManager.setBoxPlaceCompatibility(null);
      this.init();
    },
    init() {
      
      this.resize();


      this.areaOver = null;
      this.areaChosen = DataManager.getBoxPlaceCompatibility();
      this.items = [];
      this.itemsFloor = [];
      this.itemsCeiling = [];
      this.itemsAttics = [];

      moduleService.getProductById(DataManager.getData().box).then(
            response => {
              this.box = response;

              switch(this.areaChosen)
              {
                case 'floor':
                  this.itemsFloor = [{id: response.id, name: response.name, image: response.image, codeImage3DFace: response.codeImage3DFace}];
                break;
                case 'ceiling':
                  this.itemsCeiling = [{id: response.id, name: response.name, image: response.image, codeImage3DFace: response.codeImage3DFace}];
                break;
                case 'attics':
                  this.itemsAttics = [{id: response.id, name: response.name, image: response.image, codeImage3DFace: response.codeImage3DFace}];
                break;
                default:
                  this.items = [{id: response.id, name: response.name, image: response.image, codeImage3DFace: response.codeImage3DFace}];
                break;
              }
              
            },
            errors => {
              
            }
          );
      
    },
    log(evt) {
      console.log(evt);
    },
    gotoPreviousScreen(){
      //this.$router.go(-1);
      this.$router.push({ name: 'Components'});
    },
    gotoNextScreen(){
      this.$router.push({ name: 'InletPlace'});
    }
  }
}
</script>


<style scoped lang="scss">
@import '../sass/variables.scss';

  #scene {
    
  }

  #scene .scene-container {
    
  }

  #scene .scene-background, #scene .scene-drop-images, #scene .scene-drop-areas, #scene .scene-remove-images {
    position: absolute;
  }

  #scene .scene-drop-images img, #scene .scene-remove-images .btn {
    position: absolute;
  }

  #scene .scene-drop-areas .drop-area {
    position: absolute;
  } 
  
  .container {
    max-width: 1920px;
  }

.base-area {
  width: 200px;
  height: 160px;
  background-color: #f5f5f5; 
}

.drop-area {
  opacity: 1;
  border: 2px dashed $green;
}

.drop-area.over {
  background-color: $greenOpacity50;
}

.drag-object {
   width: 120px;
   height: 120px;
}

.drag-object img {
   max-width: 100px;
   max-height: 100px;
   border-radius: 60px;
}

.drag-object.sortable-chosen {
  /*opacity: 0;*/
}

.drag-object.sortable-chosen {
  opacity: 1;
  background-color: white;
  border-radius: 60px;
}

.drop-area.over .drag-object, .drag-object.hidden {
  opacity: 0;
  width: 0px;
  height: 0px;
}


a.btn.remove {
  background-image: url(../assets/images/ico-remove.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 18px;
  width: 48px;
  height: 48px;
}

</style>