
import apiService from './apiService';

export default class moduleService {


    static getCategories () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/category/list")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static getProductById (id) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/product/get/"+id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static getProductsByCategoryCode (categoryCode) {

        return new Promise((resolve, reject) => {
            
            apiService.post("/product/listByCategoryCode", {code: categoryCode})
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });

    }

}
