<template>
  <div id="page" class="p-0 d-flex flex-column h-100">
    <div>
      <bar-component />
      <header-component />
    </div>
    <div id="content" class="p-3 flex-grow-1 d-flex flex-column align-items-center">
      <div class="d-flex flex-grow-1 flex-column justify-content-center col col-12 col-md-8">
        <div class="mb-5">
          <p v-html="$t('airSystem.introduction')"></p>
        </div>
        <div class="mt-4 text-center">
          <h4>{{ $t("airSystem.instruction") }}</h4>
        </div>
        <div class="mt-4 d-flex justify-content-center gap-5">
          <div class="choice auto text-center" :class="{active: airSystemCompatibility == 'auto'}" @click="airSystemChange('auto')">
            <span>{{ $t("airSystem.auto_label") }}</span>
          </div>
          <div class="choice humidity text-center" :class="{active: airSystemCompatibility == 'humidity'}" @click="airSystemChange('humidity')">
            <span>{{ $t("airSystem.humidity_label") }}</span>
          </div>
        </div>
        
      </div>
      
    </div>

    <div class="d-flex w-100 justify-content-between fixed-bottom">
      <div class="">
        <button class="btn btn-lg btn-primary" @click="gotoPreviousScreen()">{{ $t("global.previous_label") }}</button>
      </div>
      <div class="">
        <button class="btn btn-lg btn-primary" :disabled="airSystemCompatibility==null" @click="gotoNextScreen()">{{ $t("global.next_label") }}</button>
      </div>
    </div>
    
  </div>


</template>

<script>
import DataManager from '@/model/DataManager'

import BarComponent from '../components/BarComponent.vue'
import HeaderComponent from '../components/HeaderComponent.vue'

export default {
  name: 'AirSystemView',
  components: {
    'bar-component': BarComponent,
    'header-component': HeaderComponent
  },
  data: function () {
    return {
      data: null,
      airSystemCompatibility: null
    }
  },
  mounted() {
   this.airSystemCompatibility = DataManager.getAirSystemCompatibility();
  },
  methods: {
    airSystemChange(airSystem)
    {
      DataManager.setAirSystemCompatibility(airSystem);
      this.airSystemCompatibility = DataManager.getAirSystemCompatibility();
    },
    gotoPreviousScreen(){
      this.$router.push({ name: 'AirType'});
      //this.$router.go(-1);
    },
    gotoNextScreen(){
      this.$router.push({ name: 'Components'});
    }
  }
}
</script>


<style scoped lang="scss">
@import '../sass/variables.scss';

.choice {
  width: 100px;
  padding-top: 100px;
  background-position: top center;
  background-repeat: no-repeat;
  font-weight: 700;
}

.choice.active {
  color: $blue;
}

.choice.auto {
  background-image: url(../assets/images/air-system-auto-off.svg);
}

.choice.auto.active {
  background-image: url(../assets/images/air-system-auto-on.svg);
}

.choice.humidity {
  background-image: url(../assets/images/air-system-humidity-off.svg);
}

.choice.humidity.active {
  background-image: url(../assets/images/air-system-humidity-on.svg);
}

</style>