<template>
  <div class="w-100"> 
    <div id="scene" class="">
      <div class="scene-container mx-auto p-0" :style="'width: '+(sceneWidth*sceneScale)+'px; height: '+(sceneHeight*sceneScale)+'px; transform-origin: top left; transform: scale('+sceneScale+');'">
        <div class="scene-background">
          <img v-if="housingTypeCompatibility == 'individual'" src="/images/scenes/perspective/bg-individual.png">
          <img v-if="housingTypeCompatibility == 'collective'" src="/images/scenes/perspective/bg-collective.png">
        </div>
        <div class="scene-drop-images">

          <div v-if="(layer =='all' || layer =='inlet') && inlet">
            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'double'" :src="'/images/scenes/perspective/inlet-collective-double.png'">
          </div>

          <div v-if="(layer =='all' || layer =='extraction') && extraction">
            <img v-if="housingTypeCompatibility == 'collective'" :src="'/images/scenes/perspective/extraction-collective.png'">
          </div>

          
          <!-- inlet -->

          
          <div v-if="(layer =='all' || layer =='inlet') && inlet">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && ((itemsInArea('living') && itemsLiving.length>0) || (layer == 'all' && inletsInArea('living')))" :src="'/images/scenes/perspective/inlet-individual-simple-living.png'">
          
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && ((itemsInArea('bed') && itemsBed.length>0) || (layer == 'all' && inletsInArea('bed')))" :src="'/images/scenes/perspective/inlet-individual-simple-bed.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && ((itemsInArea('bath') && itemsBath.length>0) || (layer == 'all' && inletsInArea('bath')))" :src="'/images/scenes/perspective/inlet-individual-simple-bath.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && ((itemsInArea('rest') && itemsRest.length>0) || (layer == 'all' && inletsInArea('rest')))" :src="'/images/scenes/perspective/inlet-individual-simple-rest.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && ((itemsInArea('kitchen') && itemsKitchen.length>0) || (layer == 'all' && inletsInArea('kitchen')))" :src="'/images/scenes/perspective/inlet-individual-simple-kitchen.png'">
            
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && ((itemsInArea('living') && itemsLiving.length>0) || (layer == 'all' && inletsInArea('living')))" :src="'/images/scenes/perspective/inlet-individual-double-living.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && ((itemsInArea('bed') && itemsBed.length>0) || (layer == 'all' && inletsInArea('bed')))" :src="'/images/scenes/perspective/inlet-individual-double-bed.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && ((itemsInArea('bath') && itemsBath.length>0) || (layer == 'all' && inletsInArea('bath')))" :src="'/images/scenes/perspective/inlet-individual-double-bath.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && ((itemsInArea('rest') && itemsRest.length>0) || (layer == 'all' && inletsInArea('rest')))" :src="'/images/scenes/perspective/inlet-individual-double-rest.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && ((itemsInArea('kitchen') && itemsKitchen.length>0) || (layer == 'all' && inletsInArea('kitchen')))" :src="'/images/scenes/perspective/inlet-individual-double-kitchen.png'">

            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'simple' && ((itemsInArea('living') && itemsLiving.length>0) || (layer == 'all' && inletsInArea('living')))" :src="'/images/scenes/perspective/inlet-collective-simple-living.png'">
          
            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'simple' && ((itemsInArea('bed') && itemsBed.length>0) || (layer == 'all' && inletsInArea('bed')))" :src="'/images/scenes/perspective/inlet-collective-simple-bed.png'">
            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'simple' && ((itemsInArea('bath') && itemsBath.length>0) || (layer == 'all' && inletsInArea('bath')))" :src="'/images/scenes/perspective/inlet-collective-simple-bath.png'">
            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'simple' && ((itemsInArea('rest') && itemsRest.length>0) || (layer == 'all' && inletsInArea('rest')))" :src="'/images/scenes/perspective/inlet-collective-simple-rest.png'">
          
            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'simple' && ((itemsInArea('kitchen') && itemsKitchen.length>0) || (layer == 'all' && inletsInArea('kitchen')))" :src="'/images/scenes/perspective/inlet-collective-simple-kitchen.png'">

            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'double' && ((itemsInArea('living') && itemsLiving.length>0) || (layer == 'all' && inletsInArea('living')))" :src="'/images/scenes/perspective/inlet-collective-double-living.png'">
            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'double' && ((itemsInArea('bed') && itemsBed.length>0) || (layer == 'all' && inletsInArea('bed')))" :src="'/images/scenes/perspective/inlet-collective-double-bed.png'">
            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'double' && ((itemsInArea('bath') && itemsBath.length>0) || (layer == 'all' && inletsInArea('bath')))" :src="'/images/scenes/perspective/inlet-collective-double-bath.png'">
            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'double' && ((itemsInArea('rest') && itemsRest.length>0) || (layer == 'all' && inletsInArea('rest')))" :src="'/images/scenes/perspective/inlet-collective-double-rest.png'">
            <img v-if="housingTypeCompatibility == 'collective' && airTypeCompatibility == 'double' && ((itemsInArea('kitchen') && itemsKitchen.length>0) || (layer == 'all' && inletsInArea('kitchen')))" :src="'/images/scenes/perspective/inlet-collective-double-kitchen.png'">                        
        
          </div>
        
          <!-- /inlet -->

          <!-- extraction -->
          <div v-if="(layer == 'all' || layer =='extraction') && extraction">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && ((itemsInArea('living') && itemsLiving.length>0) || (layer == 'all' && extractionsInArea('living')))" :src="'/images/scenes/perspective/extraction-individual-simple-living-'+boxPlaceCompatibility+'.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && ((itemsInArea('bed') && itemsBed.length>0) || (layer == 'all' && extractionsInArea('bed')))" :src="'/images/scenes/perspective/extraction-individual-simple-bed-'+boxPlaceCompatibility+'.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && ((itemsInArea('bath') && itemsBath.length>0) || (layer == 'all' && extractionsInArea('bath')))" :src="'/images/scenes/perspective/extraction-individual-simple-bath-'+boxPlaceCompatibility+'.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && ((itemsInArea('rest') && itemsRest.length>0) || (layer == 'all' && extractionsInArea('rest')))" :src="'/images/scenes/perspective/extraction-individual-simple-rest-'+boxPlaceCompatibility+'.png'">
            
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && ((itemsInArea('living') && itemsLiving.length>0) || (layer == 'all' && extractionsInArea('living')))" :src="'/images/scenes/perspective/extraction-individual-double-living.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && ((itemsInArea('bed') && itemsBed.length>0) || (layer == 'all' && extractionsInArea('bed')))" :src="'/images/scenes/perspective/extraction-individual-double-bed.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && ((itemsInArea('bath') && itemsBath.length>0) || (layer == 'all' && extractionsInArea('bath')))" :src="'/images/scenes/perspective/extraction-individual-double-bath.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && ((itemsInArea('rest') && itemsRest.length>0) || (layer == 'all' && extractionsInArea('rest')))" :src="'/images/scenes/perspective/extraction-individual-double-rest.png'">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && ((itemsInArea('kitchen') && itemsKitchen.length>0) || (layer == 'all' && extractionsInArea('kitchen')))" :src="'/images/scenes/perspective/extraction-individual-double-kitchen.png'">


            <img v-if="housingTypeCompatibility == 'collective' && ((itemsInArea('living') && itemsLiving.length>0) || (layer == 'all' && extractionsInArea('living')))" :src="'/images/scenes/perspective/extraction-collective-living.png'">
            <img v-if="housingTypeCompatibility == 'collective' && ((itemsInArea('bed') && itemsBed.length>0) || (layer == 'all' && extractionsInArea('bed')))" :src="'/images/scenes/perspective/extraction-collective-bed.png'">
            <img v-if="housingTypeCompatibility == 'collective' && ((itemsInArea('bath') && itemsBath.length>0) || (layer == 'all' && extractionsInArea('bath')))" :src="'/images/scenes/perspective/extraction-collective-bath.png'">
            <img v-if="housingTypeCompatibility == 'collective' && ((itemsInArea('rest') && itemsRest.length>0) || (layer == 'all' && extractionsInArea('rest')))" :src="'/images/scenes/perspective/extraction-collective-rest.png'">
            <img v-if="housingTypeCompatibility == 'collective' && ((itemsInArea('kitchen') && itemsKitchen.length>0) || (layer == 'all' && extractionsInArea('kitchen')))" :src="'/images/scenes/perspective/extraction-collective-kitchen.png'">                        
          </div>
          <!-- /extraction -->

          <!-- box -->
          <img v-if="box && housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && boxIsInArea('floor')" :src="'/images/scenes/perspective/box-floor-individual-simple-'+box.codeImage3DPerspective+'.png'">
          <img v-if="box && housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && boxIsInArea('ceiling')" :src="'/images/scenes/perspective/box-ceiling-individual-simple-'+box.codeImage3DPerspective+'.png'">
          <img v-if="box && housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && boxIsInArea('attics')" :src="'/images/scenes/perspective/box-attics-individual-simple-'+box.codeImage3DPerspective+'.png'">

          <img v-if="box && housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && boxIsInArea('floor')" :src="'/images/scenes/perspective/box-floor-individual-double-'+box.codeImage3DPerspective+'.png'">
          <img v-if="box && housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && boxIsInArea('ceiling')" :src="'/images/scenes/perspective/box-ceiling-individual-double-'+box.codeImage3DPerspective+'.png'">
          <img v-if="box && housingTypeCompatibility == 'individual' && airTypeCompatibility == 'double' && boxIsInArea('attics')" :src="'/images/scenes/perspective/box-attics-individual-double-'+box.codeImage3DPerspective+'.png'">

          <img v-if="box && housingTypeCompatibility == 'collective'" :src="'/images/scenes/perspective/box-collective-'+box.codeImage3DPerspective+'.png'">
          
          <!-- /box -->

          <div v-if="(layer == 'all' || layer =='extraction') && extraction">
            <img v-if="housingTypeCompatibility == 'individual' && airTypeCompatibility == 'simple' && ((itemsInArea('kitchen') && itemsKitchen.length>0) || (layer == 'all' && extractionsInArea('kitchen')))" :src="'/images/scenes/perspective/extraction-individual-simple-kitchen-'+boxPlaceCompatibility+'.png'">
          </div>
            

        </div>
        <div v-if="draggableActive==true" class="scene-drop-areas">
          
          <draggable v-if="housingTypeCompatibility == 'individual'" id="living" :list="itemsLiving" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'living', 'bg': !itemsInArea('living')}" style="width: 615px; height: 363px; left: 400px; top: 47px;">
            <template #item="{element}">
              <div :data-id="element.id" class="drag-object hidden">
              </div>
            </template>
          </draggable>
        
          <draggable v-if="housingTypeCompatibility == 'individual'" id="bed" :list="itemsBed" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'bed', 'bg': !itemsInArea('bed')}" style="width: 276px; height: 355px; left: 345px; top: 429px;">
            <template #item="{element}">
              <div :data-id="element.id" class="drag-object hidden">
              </div>
            </template>
          </draggable>

          <draggable v-if="housingTypeCompatibility == 'individual'" id="bath" :list="itemsBath" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'bath', 'bg': !itemsInArea('bath')}" style="width: 210px; height: 355px; left: 672px; top: 430px;">
            <template #item="{element}">
              <div :data-id="element.id" class="drag-object hidden">
              </div>
            </template>
          </draggable>

          <draggable v-if="housingTypeCompatibility == 'individual'" id="rest" :list="itemsRest" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'rest', 'bg': !itemsInArea('rest')}" style="width: 135px; height: 210px; left: 1038px; top: 200px;">
            <template #item="{element}">
              <div :data-id="element.id" class="drag-object hidden">
              </div>
            </template>
          </draggable> 
          
          <draggable v-if="housingTypeCompatibility == 'individual'" id="kitchen" :list="itemsKitchen" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'kitchen', 'bg': !itemsInArea('kitchen')}" style="width: 524px; height: 196px; left: 1044px; top: 588px;">
            <template #item="{element}">
              <div :data-id="element.id" class="drag-object hidden">
              </div>
            </template>
          </draggable> 






          <draggable v-if="housingTypeCompatibility == 'collective'" id="living" :list="itemsLiving" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'living', 'bg': !itemsInArea('living')}" style="width: 495px; height: 303px; left: 710px; top: 127px;">
            <template #item="{element}">
              <div :data-id="element.id" class="drag-object hidden">
              </div>
            </template>
          </draggable>
        
          <draggable v-if="housingTypeCompatibility == 'collective'" id="bed" :list="itemsBed" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'bed', 'bg': !itemsInArea('bed')}" style="width: 298px; height: 209px; left: 391px; top: 83px;">
            <template #item="{element}">
              <div :data-id="element.id" class="drag-object hidden">
              </div>
            </template>
          </draggable>
          

          <draggable v-if="housingTypeCompatibility == 'collective'" id="bath" :list="itemsBath" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'bath', 'bg': !itemsInArea('bath')}" style="width: 245px; height: 196px; left: 555px; top: 553px;">
            <template #item="{element}">
              <div :data-id="element.id" class="drag-object hidden">
              </div>
            </template>
          </draggable>

          <draggable v-if="housingTypeCompatibility == 'collective'" id="rest" :list="itemsRest" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'rest', 'bg': !itemsInArea('rest')}" style="width: 148px; height: 196px; left: 821px; top: 553px;">
            <template #item="{element}">
              <div :data-id="element.id" class="drag-object hidden">
              </div>
            </template>
          </draggable> 
          
          <draggable v-if="housingTypeCompatibility == 'collective'" id="kitchen" :list="itemsKitchen" item-key="id" group="items" :move="move" class="drop-area" :class="{'over': areaOver == 'kitchen', 'bg': !itemsInArea('kitchen')}" style="width: 312px; height: 304px; left: 983px; top: 445px;">
            <template #item="{element}">
              <div :data-id="element.id" class="drag-object hidden">
              </div>
            </template>
          </draggable> 
        
        </div>
        <div v-if="draggableActive==true" class="scene-remove-images">
          
            <a v-if="housingTypeCompatibility == 'individual' && itemsInArea('living')" class="btn btn-lg btn-primary remove" @click="reset('living')" style="left: 400px; top: 47px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
            <a v-if="housingTypeCompatibility == 'individual' && itemsInArea('bed')" class="btn btn-lg btn-primary remove" @click="reset('bed')" style="left: 345px; top: 429px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
            <a v-if="housingTypeCompatibility == 'individual' && itemsInArea('bath')" class="btn btn-lg btn-primary remove" @click="reset('bath')" style="left: 672px; top: 430px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
            <a v-if="housingTypeCompatibility == 'individual' && itemsInArea('rest')" class="btn btn-lg btn-primary remove" @click="reset('rest')" style="left: 1038px; top: 200px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
            <a v-if="housingTypeCompatibility == 'individual' && itemsInArea('kitchen')" class="btn btn-lg btn-primary remove" @click="reset('kitchen')" style="left: 1044px; top: 588px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>


            <a v-if="housingTypeCompatibility == 'collective' && itemsInArea('living')" class="btn btn-lg btn-primary remove" @click="reset('living')" style="left: 710px; top: 127px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
            <a v-if="housingTypeCompatibility == 'collective' && itemsInArea('bed')" class="btn btn-lg btn-primary remove" @click="reset('bed')" style="left: 391px; top: 231px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
            <a v-if="housingTypeCompatibility == 'collective' && itemsInArea('bath')" class="btn btn-lg btn-primary remove" @click="reset('bath')" style="left: 555px; top: 552px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
            <a v-if="housingTypeCompatibility == 'collective' && itemsInArea('rest')" class="btn btn-lg btn-primary remove" @click="reset('rest')" style="left: 821px; top: 688px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
            <a v-if="housingTypeCompatibility == 'collective' && itemsInArea('kitchen')" class="btn btn-lg btn-primary remove" @click="reset('kitchen')" style="left: 983px; top: 445px;" :style="'transform-origin: top left; transform: scale('+(1/sceneScale)+')'"></a>
        </div>
      </div>
    </div>
    <div v-if="draggableActive==true" class="base-area mx-auto d-flex flex-column align-items-center justify-content-center mt-3">
      <draggable id="dock" :list="items" item-key="id" group="items" :move="move" @remove="remove" class="d-flex align-items-center justify-content-center" :class="{'over': areaOver == 'dock'}">
        <template #item="{element}">
          <div :data-id="element.id" class="drag-object d-flex align-items-center justify-content-center">
            <img :src="element.image" />
          </div>
        </template>
      </draggable>
      <h4 v-if="layer=='inlet' && inlet">{{inlet.name}}</h4>
      <h4 v-if="layer=='extraction' && extraction">{{extraction.name}}</h4>
    </div>
  </div>
</template>

<script>

  import draggable from 'vuedraggable';
  import DataManager from '@/model/DataManager';
  import moduleService from "@/services/moduleService";

  import $ from "jquery";
  
  export default {
    name: 'InletPlaceView',
    components: {
      draggable
    },
    props: {
      draggableActive: Boolean,
      layer: String,
      refresh: Boolean
    },
    data: function () {
      return {
        sceneWidth: 1920,
        sceneHeight: 900,
        sceneMaxWidth: 1920,
        sceneScale: 1,
        box: null,
        inlet: null,
        extraction: null,
        duct: null,
        areaOver: null,
        areasSelected: [],
        initialItems: [],
        items: [],
        itemsLiving: [],
        itemsBed: [],
        itemsBath: [],
        itemsRest: [],
        itemsKitchen: []
      }
    },
    computed: {
      readyToNext()
      {
        
        return true;
      }
    },
    watch: {
      refresh: function(){
        if(this.refresh)
        {
          this.init();
        }
      }
      
    },
    mounted() {
     
     this.init();

     if(!window.onresize)
      {
        this.addListeners();
      }
    }, 
    methods:
    {
      removeListeners()
      {
        window.onresize = null;
      },
      addListeners()
      {
        window.onresize = ()=> { this.resize(); };
      },
      resize()
      {
        
        let domSceneWidth = $("#app").width()/12*10;
        let screenSceneHeight = screen.availHeight;
        let scale = 1;
        let marginTopAndBottom = 420;
        
        if(domSceneWidth > this.sceneMaxWidth)
        {
          domSceneWidth = this.sceneMaxWidth;
        }
        
        scale = domSceneWidth / this.sceneWidth;

        if( (scale * this.sceneHeight) > (screenSceneHeight - marginTopAndBottom))
        {
          let scaleSupp = (screenSceneHeight - marginTopAndBottom) / (scale * this.sceneHeight);
          scale = scale * scaleSupp;
        }

        this.sceneScale = scale;
        
      },
      evaluate()
      {
        if(this.box != null && this.inlet != null && this.extraction != null && this.duct != null)
        {
          var success = false;
          let inletErrors = this.evaluateInlets();
          let extractionErrors = this.evaluateExtractions();
          let housingTypeErrors = this.evaluateHousingType();
          let airTypeErrors = this.evaluateAirType();
          let airSystemErrors = this.evaluateAirSystem();
          let boxPlaceErrors = this.evaluateBoxPlace();
          
          
          let productIncompatibilityErrors = this.evaluateProductIncompatibility();

          if(inletErrors.length == 0 && extractionErrors.length == 0 && housingTypeErrors.length == 0 && airTypeErrors.length == 0 && airSystemErrors.length == 0 && boxPlaceErrors.length == 0 && productIncompatibilityErrors.length == 0)
          {
            success = true;
          }

          var errors = {
            "inlet": inletErrors,
            "extraction": extractionErrors,
            "housingType": housingTypeErrors,
            "airType": airTypeErrors,
            "airSystem": airSystemErrors,
            "boxPlace": boxPlaceErrors,
            "productIncompatibility": productIncompatibilityErrors,
          }
          

          
          
          this.$emit("evaluated", {success: success, errors: errors});
        }
        
      },
      evaluateHousingType()
      {
        let errors = [];
        let housingTypeCompatibility = DataManager.getHousingTypeCompatibility();
        if(!this.inlet.housingTypeCompatibility.includes(housingTypeCompatibility))
        {
          errors.push("inlet "+this.inlet.name+" not compatible with housing type "+housingTypeCompatibility);
        }

        if(!this.extraction.housingTypeCompatibility.includes(housingTypeCompatibility))
        {
          errors.push("extraction "+this.extraction.name+" not compatible with housing type "+housingTypeCompatibility);
        }

        if(!this.box.housingTypeCompatibility.includes(housingTypeCompatibility))
        {
          errors.push("box "+this.box.name+" not compatible with housing type "+housingTypeCompatibility);
        }

        if(!this.duct.housingTypeCompatibility.includes(housingTypeCompatibility))
        {
          errors.push("duct "+this.duct.name+" not compatible with housing type "+housingTypeCompatibility);
        }

        return errors;
      },
      evaluateAirType()
      {
        let errors = [];

        let airTypeCompatibility = DataManager.getAirTypeCompatibility();
        if(!this.inlet.airTypeCompatibility.includes(airTypeCompatibility))
        {
          errors.push("inlet "+this.inlet.name+" not compatible with air type "+airTypeCompatibility);
        }

        if(!this.extraction.airTypeCompatibility.includes(airTypeCompatibility))
        {
          errors.push("extraction "+this.extraction.name+" not compatible with air type "+airTypeCompatibility);
        }

        if(!this.box.airTypeCompatibility.includes(airTypeCompatibility))
        {
          errors.push("box "+this.box.name+" not compatible with air type "+airTypeCompatibility);
        }

        if(!this.duct.airTypeCompatibility.includes(airTypeCompatibility))
        {
          errors.push("duct "+this.duct.name+" not compatible with air type "+airTypeCompatibility);
        }

        return errors;
        
      },
      evaluateAirSystem()
      {
        let errors = [];
        let airSystemCompatibility = DataManager.getAirSystemCompatibility();
        if(!this.inlet.airSystemCompatibility.includes(airSystemCompatibility))
        {
          errors.push("inlet "+this.inlet.name+" not compatible with air system "+airSystemCompatibility);
        }

        if(!this.extraction.airSystemCompatibility.includes(airSystemCompatibility))
        {
          errors.push("extraction "+this.extraction.name+" not compatible with air system "+airSystemCompatibility);
        }

        if(!this.box.airSystemCompatibility.includes(airSystemCompatibility))
        {
          errors.push("box "+this.box.name+" not compatible with air system "+airSystemCompatibility);
        }

        if(!this.duct.airSystemCompatibility.includes(airSystemCompatibility))
        {
          errors.push("duct "+this.duct.name+" not compatible with air system "+airSystemCompatibility);
        }

        return errors;
        
      },
      evaluateBoxPlace()
      {
        let errors = [];


        let boxPlaceCompatibility = DataManager.getBoxPlaceCompatibility();

        if(boxPlaceCompatibility)
        {

          if(!this.inlet.boxPlaceCompatibility.includes(boxPlaceCompatibility))
          {
            errors.push("inlet "+this.inlet.name+" not compatible with box place "+boxPlaceCompatibility);
          }

          if(!this.extraction.boxPlaceCompatibility.includes(boxPlaceCompatibility))
          {
            errors.push("extraction "+this.extraction.name+" not compatible with box place "+boxPlaceCompatibility);
          }

          if(!this.box.boxPlaceCompatibility.includes(boxPlaceCompatibility))
          {
            errors.push("box "+this.box.name+" not compatible with box place "+boxPlaceCompatibility);
          }

          if(!this.duct.boxPlaceCompatibility.includes(boxPlaceCompatibility))
          {
            errors.push("duct "+this.duct.name+" not compatible with box place "+boxPlaceCompatibility);
          }

        }

        return errors;
      },
      evaluateProductIncompatibility()
      {
        let errors = [];
        
        if(this.inlet.conflictingProducts.includes(this.box.id))
        {
          errors.push("inlet "+this.inlet.name+" not compatible with box "+this.box.name);
        }
        
        if(this.inlet.conflictingProducts.includes(this.extraction.id))
        {
          errors.push("inlet "+this.inlet.name+" not compatible with extraction "+this.extraction.name);
        }

        if(this.inlet.conflictingProducts.includes(this.duct.id))
        {
          errors.push("inlet "+this.inlet.name+" not compatible with duct "+this.duct.name);
        }


        if(this.box.conflictingProducts.includes(this.inlet.id))
        {
          errors.push("box "+this.box.name+" not compatible with inlet "+this.inlet.name);
        }
        
        if(this.box.conflictingProducts.includes(this.extraction.id))
        {
          errors.push("box "+this.box.name+" not compatible with extraction "+this.extraction.name);
        }

        if(this.box.conflictingProducts.includes(this.duct.id))
        {
          errors.push("box "+this.box.name+" not compatible with duct "+this.duct.name);
        }


        if(this.extraction.conflictingProducts.includes(this.box.id))
        {
          errors.push("extraction "+this.extraction.name+" not compatible with box "+this.box.name);
        }

        if(this.extraction.conflictingProducts.includes(this.inlet.id))
        {
          errors.push("extraction "+this.extraction.name+" not compatible with inlet "+this.inlet.name);
        }

        if(this.extraction.conflictingProducts.includes(this.duct.id))
        {
          errors.push("extraction "+this.extraction.name+" not compatible with duct "+this.duct.name);
        }


        if(this.duct.conflictingProducts.includes(this.box.id))
        {
          errors.push("duct "+this.duct.name+" not compatible with box "+this.box.name);
        }

        if(this.duct.conflictingProducts.includes(this.inlet.id))
        {
          errors.push("duct "+this.duct.name+" not compatible with inlet "+this.inlet.name);
        }

        if(this.duct.conflictingProducts.includes(this.extraction.id))
        {
          errors.push("duct "+this.duct.name+" not compatible with extraction "+this.extraction.name);
        }        

        return errors;
      },
      evaluateInlets()
      {
        let errors = [];
      
        // INLETS
        let inletLivingFound = false;
        let inletBedFound = false;
        let inletPlaces = DataManager.getInletPlaces();
        if(inletPlaces)
        {
          for(let inletPlace of inletPlaces)
          {
            switch(inletPlace)
            {
              case "bath":
                errors.push(
                  {
                    value: "not required",
                    type: "bath",
                    msg: "inlet in bathroom"
                  }
                );
              break;
              case "rest":
                errors.push(
                  {
                    value: "not required",
                    type: "rest",
                    msg: "inlet in restroom"
                  }
                );
              break;
              case "kitchen":
                errors.push(
                  {
                    value: "not required",
                    type: "kitchen",
                    msg: "inlet in kitchen"
                  }  
                );
              break;
              case "living":
                inletLivingFound=true;
              break;
              case "bed":
                inletBedFound=true;
              break;
            }
          }
        }
          if(!inletLivingFound)
          {
            errors.push(
              {
                value: "required",
                type: "living",
                msg: "no inlet in living room"
              }
            );
          }

          if(!inletBedFound)
          {
            errors.push(
              {
                value: "required",
                type: "bed",
                msg: "no inlet in bedroom"
              }
            );
          }
        
        // /INLETS
          
          return errors;
      },
      evaluateExtractions()
      {
        let errors = [];
        // EXTRACTIONS
        let extractionRestFound = false;
        let extractionBathFound = false;
        let extractionKitchenFound = false;
        let extractionPlaces = DataManager.getExtractionPlaces();
        if(extractionPlaces)
        {
          for(let extractionPlace of extractionPlaces)
          {
            switch(extractionPlace)
            {
              case "bed":
                errors.push(
                  {
                    value: "not required",
                    type: "bed",
                    msg: "extraction in bedroom"
                  }
                );
              break;
              case "living":
                errors.push(
                  {
                    value: "not required",
                    type: "living",
                    msg: "extraction in living room"
                  }
                );
              break;
              case "kitchen":
                extractionKitchenFound=true;
              break;
              case "bath":
                extractionBathFound=true;
              break;
              case "rest":
                extractionRestFound=true;
              break;
            }
          }
        }
          if(!extractionKitchenFound)
          {
            errors.push(
                {
                  value: "required",
                  type: "kitchen",
                  msg: "no extraction in kitchen"
                }
              );
          }

          if(!extractionBathFound)
          {
            errors.push(
              {
                value: "required",
                type: "bath",
                msg: "no extraction in bathroom"
              }
            );
              
          }

          if(!extractionRestFound)
          {
            errors.push(
              {
                value: "required",
                type: "rest",
                msg: "no extraction in restroom"
              }
            );
          }
        
        // /EXTRACTIONS


        return errors;
      },
      itemsInArea(area)
      {
        
        for(var a of this.areasSelected)
        {
          if(area == a)
          {
            return true;
          }
        }
  
        return false;
      },
      inletsInArea(area)
      {
        var inletPlaces = DataManager.getInletPlaces();
        
        if(inletPlaces)
        {
          for(var a of inletPlaces)
          {
            if(area == a)
            {
              return true;
            }
          }
        }
  
        return false;
      },
      extractionsInArea(area)
      {
        var extractionPlaces = DataManager.getExtractionPlaces();
        if(extractionPlaces)
        {
          for(var a of extractionPlaces)
          {
            if(area == a)
            {
              return true;
            }
          }
        }
  
        return false;
      },
      boxIsInArea(area)
      {
        if(area == DataManager.getBoxPlaceCompatibility())
        {
          return true;
        }
  
        return false;
      },
      move(evt) {
        //console.log(evt);
        this.areaOver = evt.to.id;
      },
      remove(evt) {
        //console.log("remove from: "+evt.from.id+" to: "+evt.to.id);
        this.addArea(evt.to.id);
  
        if(this.layer == 'inlet')
        {
          DataManager.setInletPlaces(this.areasSelected);
        }else if(this.layer == 'extraction')
        {
          DataManager.setExtractionPlaces(this.areasSelected);
        }
        
  
        this.areaOver = null;
  
        this.initItems();
      },
      addArea(area)
      {
        var found = false;
        for(let i=0; i<this.areasSelected.length; i++)
        {
          if(this.areasSelected[i] == area)
          {
            found = true;
          }
        }
  
        if(!found)
        {
          this.areasSelected.push(area);
        }
  
      },
      reset(area) {
        for(let i=0; i<this.areasSelected.length; i++)
        {
          if(this.areasSelected[i] == area)
          {
            this.areasSelected.splice(i, 1);
          }
        }
        if(this.layer == 'inlet')
        {
          DataManager.setInletPlaces(this.areasSelected);
        }else if(this.layer == 'extraction')
        {
          DataManager.setExtractionPlaces(this.areasSelected);
        }

        this.init();
      },
      init() {

        this.resize();
        
        this.areaOver = null;

        if(this.layer == 'inlet')
        {
          this.areasSelected = DataManager.getInletPlaces();
        }else if(this.layer == 'extraction')
        {
          this.areasSelected = DataManager.getExtractionPlaces();
        }
        
        this.housingTypeCompatibility = DataManager.getHousingTypeCompatibility();
        this.airTypeCompatibility = DataManager.getAirTypeCompatibility();
        this.boxPlaceCompatibility = DataManager.getBoxPlaceCompatibility();
  
        if(this.areasSelected == null)
        {
          this.areasSelected = [];
        }
  
        this.items = [];
        this.itemsLiving = [];
        this.itemsBed = [];
        this.itemsBath = [];
        this.itemsRest = [];
        this.itemsKitchen = [];
  
        moduleService.getProductById(DataManager.getData().box).then(
          response => {
            this.box = response;
            this.evaluate();
          },
          errors => {
            
          }
        );

        moduleService.getProductById(DataManager.getData().duct).then(
          response => {
            this.duct = response;
            this.evaluate();
          },
          errors => {
            
          }
        );
  
        moduleService.getProductById(DataManager.getData().inlet).then(
          response => {
            this.inlet = response;
            this.evaluate();

            if(this.layer == 'inlet')
            {
              
              if(this.areasSelected.length > 0)
              {
                for(var area of this.areasSelected)
                {
                  switch(area)
                  {
                    case 'living':
                      this.itemsLiving = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                    break;
                    case 'bed':
                      this.itemsBed = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                    break;
                    case 'bath':
                      this.itemsBath = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                    break;
                    case 'rest':
                      this.itemsRest = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                    break;
                    case 'kitchen':
                      this.itemsKitchen = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                    break;
                  }
                }
              }
    
                this.initialItems = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                this.initItems();

            }
            
          },
          errors => {
            
          }
        );

        moduleService.getProductById(DataManager.getData().extraction).then(
          response => {
            this.extraction = response;
            this.evaluate();

            if(this.layer == 'extraction')
            {
              
              if(this.areasSelected.length > 0)
              {
                for(var area of this.areasSelected)
                {
                  switch(area)
                  {
                    case 'living':
                      this.itemsLiving = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                    break;
                    case 'bed':
                      this.itemsBed = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                    break;
                    case 'bath':
                      this.itemsBath = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                    break;
                    case 'rest':
                      this.itemsRest = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                    break;
                    case 'kitchen':
                      this.itemsKitchen = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                    break;
                  }
                }
              }
    
                this.initialItems = [{id: response.id, name: response.name, image: response.image, codeImage3DPerspective: response.codeImage3DPerspective}];
                this.initItems();

            }
            
          },
          errors => {
            
          }
        );
        
      },
      initItems()
      {
        this.items = JSON.parse(JSON.stringify(this.initialItems));
        
      },
      log(evt) {
        console.log(evt);
      }
      
    }
  }
  </script>
  
  
  <style scoped lang="scss">
  @import '../sass/variables.scss';
  
    #scene {
      
    }
  
    #scene .scene-container {
      
    }
  
    #scene .scene-background, #scene .scene-drop-images, #scene .scene-drop-areas, #scene .scene-remove-images {
      position: absolute;
    }
  
    #scene .scene-drop-images img, #scene .scene-remove-images .btn {
      position: absolute;
    }
  
    #scene .scene-drop-areas .drop-area {
      position: absolute;
    } 

  .container {
    max-width: 1920px;
  }
    
  
  .base-area {
    width: 200px;
    height: 160px;
    background-color: #f5f5f5; 
  }
  
  .drop-area {
    opacity: 1;
    border: 2px dashed $green;
  }
  
  .drop-area.over.bg {
    background-color: $greenOpacity50;
  }
  
  .drag-object {
     width: 120px;
     height: 120px;
  }
  
  .drag-object img {
     max-width: 100px;
     max-height: 100px;
     border-radius: 50px;
  }
  
  .drag-object.sortable-chosen {
    /*opacity: 0;*/
  }
  
  .drag-object.sortable-chosen {
    opacity: 1;
    background-color: white;
    border-radius: 60px;
  }
  
  .drop-area.over .drag-object, .drag-object.hidden {
    opacity: 0;
    width: 0px;
    height: 0px;
  }
  
  
  a.btn.remove {
    background-image: url(../assets/images/ico-remove.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 18px;
    width: 48px;
    height: 48px;
  }
  
  </style>